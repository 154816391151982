import {EnvironmentDonutChart} from "../charts/EnvironmentDonutChart";
import {FarmDialogGraph} from "../charts/FarmDialogGraph";
import {OutlinedCard} from "../../common/OutlinedCard";
import React, {useMemo} from "react";

export const HumidityBlock = (props) => {

    const {humidity, humidityList, unitPeriod} = props;

    const humidityColor = '#3E5DFF';

    const humidityChart = useMemo(() => {
        return(
            <FarmDialogGraph
                data={humidityList} label="Humidity (%)" color={humidityColor}
                ymin={0} ymax={100} unitPeriod={unitPeriod} chartHeight={180}
            />
        );
    }, [humidityList, unitPeriod]);

    return(
        <>
            <div className="farm__dialog_environment_blocks">
                <h2>Humidity</h2>
                <div className="farm__dialog_environment_blocks-flex">
                    <div className="environment-left-block">
                        <div className="environment-left-block-line">
                            <EnvironmentDonutChart
                                colorData={humidityColor} percentageData={humidity}
                                label={(humidity ? Math.floor(humidity) : '--') + '%'}
                            />
                        </div>
                    </div>
                    <div className="environment-graph-block">
                        <div className="environment-graph">
                            {humidityChart}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
