import React, {useEffect, useState} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import PreparationIcon from "../../images/icons/pic/1-PreparationFichiers-activate_1-8.png";
import FabricationIcon from "../../images/icons/pic/2-fabrication-activate_1-8.png";
import PostFabricationIcon from "../../images/icons/pic/3-Post-fabrication-activate_1-8.png";
import CheckCircleBlack from "../../images/icons/pic/check_circle_black_24dp-fill";
import InfoBlack from "../../images/icons/pic/info_black_24dp";
import ProductionNav from "../../images/icons/pic/ProductionNav";
import { useStyles } from "../../css/pic/pic.js";
import {patchPic, postPic} from "../../api/apiPic";
import {getFormattedDuration} from "../CommonFunctions";
import {PicCard} from "./Pic.class";
import {useRouteMatch} from "react-router-dom";

export const PicDetailsComponent = (props) => {
    const classes = useStyles();
    let {path} = useRouteMatch();

    const {picData, printerData} = props;

    const [copies, setCopies] = useState([]);


    const handleClickProduction = async () => {
        await patchPic(picData);

        window.location.href = `/gantt/schedule/${picData.id}`;
    }

    function getTotalDuration(){
        let totalDuration = 0;

        let slicerCards = picData.pic_cards.filter(picCard => picCard.type === PicCard.TYPE_SLICER_CONNECTION);
        let machineJobCards = picData.pic_cards.filter(picCard => picCard.type === PicCard.TYPE_MACHINE_JOB);
        let postTreatmentCards = picData.pic_cards.filter(picCard => picCard.type === PicCard.TYPE_POST_TREATMENT);

        totalDuration += slicerCards.reduce((accumulator, picCard) => accumulator + picCard.card.duration, 0);

        for(let i=0; i<machineJobCards.length; i++)
            totalDuration += machineJobCards[i].card.machine_job_time_slots.reduce((accumulator, timeSlot) => accumulator + timeSlot.duration, 0);

        totalDuration += postTreatmentCards.reduce((accumulator, picCard) => accumulator + picCard.card.duration, 0);

        return getFormattedDuration(totalDuration);
    }

    function getPreparationDuration(){
        let totalDuration = 0;
        let machine_job = picData.pic_cards.filter(card => card.type === PicCard.TYPE_MACHINE_JOB);

        if(machine_job.length && machine_job[0].data.machine_job_time_slots.length)
            machine_job[0].data.machine_job_time_slots.map((time_slot) => {
                if(time_slot.period === 'preparation')
                    totalDuration += time_slot.duration;
            })

        return getFormattedDuration(totalDuration);
    }

    function getProductionDuration(){
        let totalDuration = 0;
        let slicerCards = picData.pic_cards.filter(card => card.type === PicCard.TYPE_SLICER_CONNECTION);

        totalDuration += slicerCards.reduce((accumulator, card) => accumulator + card.data.duration, 0);

        return getFormattedDuration(totalDuration);
    }

    function getPostDuration(){
        let totalDuration = 0;

        let machineJobCards = picData.pic_cards.filter(card => card.type === PicCard.TYPE_MACHINE_JOB);
        let postTreatmentCards = picData.pic_cards.filter(card => card.type === PicCard.TYPE_POST_TREATMENT);

        if(machineJobCards.length && machineJobCards[0].data.machine_job_time_slots.length) {
            machineJobCards[0].data.machine_job_time_slots.map((time_slot) => {
                if (time_slot.period === 'extra')
                    totalDuration += time_slot.duration;
            })
        }

        totalDuration += postTreatmentCards.reduce((accumulator, card) => accumulator + card.data.duration, 0);

        return getFormattedDuration(totalDuration);
    }

    const isDisabled = () => {
        // Check if template
        if(path.includes('template')) {
            return true;
        }

        let slicerConnectionCards = picData.pic_cards.filter(card => card.type === PicCard.TYPE_SLICER_CONNECTION);

        if(slicerConnectionCards.length === 0 || !slicerConnectionCards[0].card){
            return true;
        }

        const card = slicerConnectionCards[0].card;
        let printer = card.main_printer;

        if(!printer || !card.duration)
            return true;

        // New card, use printerData
        if(printer.id === undefined && printerData)
            printer = printerData;

        if(!card.material1 || !card.brand1 || (printer && printer.technology.has_color && !card.color1) || !card.quantity1)
            return true;

        if(printer && printer.extruders_number > 1){
            if(!card.material2 || !card.brand2 || (printer.technology.has_color && !card.color2) || !card.quantity2)
                return true;
        }

        return false;
    }

    const getPicName = () => {
        return picData.name !== '' ? picData.name : '[Untitled]';
    };

    const makeCopy = () => {
        if(picData.id) {
            const picCopy = {...picData};
            picCopy.name = picCopy.name + ' - Copy';
            picCopy.original_pic = {id: picData.original_pic ? picData.original_pic.id : picData.id};
            picCopy.projects = [];
            picCopy.pic_cards = resetPicCardsData(picCopy.pic_cards);

            postPic(picCopy)
                .then(response => {
                    if(response.id) {
                        window.location = `/builder/${response.id}`;
                    }
                })
                .catch(e => {
                    console.log(e)
                    console.log('Error while copying PIC.');
                });
        }
    };

    const resetPicCardsData = (picCards) => {
        return picCards.map(picCard => {
            if(picCard.card) {
                picCard.card.id = null;
                if(picCard.card.card_platform_pieces) {
                    picCard.card.card_platform_pieces.map(cardPlatformPiece => {
                        cardPlatformPiece.id = null;
                    });
                }
                if(picCard.card.card_platform_attachments) {
                    picCard.card.card_platform_attachments.map(cardPlatformAttachment => {
                        cardPlatformAttachment.id = null;
                    });
                }
            }
            return picCard;
        });
    }


    useEffect(() => {
        // PIC has original PIC <> this PIC is not the original PIC
        if(picData.original_pic)
            setCopies([picData.original_pic].concat(picData.original_pic.copies));
        else{
            if(picData.copies !== undefined)
                setCopies([picData].concat(picData.copies));
        }
    }, [picData]);


    return (
        <div className="main__side-bar">
            <h2>{getPicName()}</h2>
            <div className="step-details">
                <Accordion expanded={true} className={classes.accordion}>
                    <AccordionSummary classes={{content: classes.accordion_summary}}>
                        <img src={PreparationIcon} className="sub-menu-icone" alt="preparation"/>
                        <h3>Setups</h3>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details" classes={{root: classes.accordion_details_root}}>
                        <ol>
                            {
                                picData.pic_cards.map((picCard, index) =>
                                    picCard.card && PicCard.DISPLAY_DATA[picCard.type].step === 1 && (
                                        <a key={index} href={`#${(picCard.type === PicCard.TYPE_BUILD_PLATFORM) ? "build-platform" : "slicer-connection"}${index+1}`}>
                                            <li key={index} className={!picCard.is_complete ? 'uncomplete' : ''}>
                                                {PicCard.DISPLAY_DATA[picCard.type].name} <CheckCircleBlack className={!picCard.is_complete ? 'check-circle-icone uncomplete' : 'check-circle-icone'}/>
                                            </li>
                                        </a>
                                    )
                                )
                            }
                        </ol>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={true} className={classes.accordion}>
                    <AccordionSummary classes={{content: classes.accordion_summary}}>
                        <img src={FabricationIcon} className="sub-menu-icone" alt={"fabrication"}/>
                        <h3>Machine steps</h3>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details" classes={{root: classes.accordion_details_root}}>
                        <ol>
                            {
                                picData.pic_cards.map((picCard, index) =>
                                    picCard.card && PicCard.DISPLAY_DATA[picCard.type].step === 2 && (
                                        <a key={'mj' +index} href={`#machine-job${index+1}`}>
                                            <li key={index} className={!picCard.is_complete ? 'uncomplete' : ''}>
                                                {PicCard.DISPLAY_DATA[picCard.type].name} <CheckCircleBlack className={!picCard.is_complete ? 'check-circle-icone uncomplete' : 'check-circle-icone'}/>
                                            </li>
                                        </a>
                                    )
                                )
                            }
                        </ol>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={true} className={classes.accordion}>
                    <AccordionSummary classes={{content: classes.accordion_summary}}>
                        <img src={PostFabricationIcon} className="sub-menu-icone" alt="post_fabrication"/>
                        <h3>Post-processing</h3>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details" classes={{root: classes.accordion_details_root}}>
                        <ol>
                            {
                                picData.pic_cards.map((picCard, index) =>
                                    picCard.card && PicCard.DISPLAY_DATA[picCard.type].step === 3 && (
                                        <a key={'pt-' + index} href={`#post-treatment${index+1}`}>
                                            <li key={index} className={!picCard.is_complete ? 'uncomplete' : ''}>
                                                {PicCard.DISPLAY_DATA[picCard.type].name} <CheckCircleBlack className={!picCard.is_complete ? 'check-circle-icone uncomplete' : 'check-circle-icone'}/>
                                            </li>
                                        </a>
                                    )
                                )
                            }
                        </ol>
                    </AccordionDetails>
                </Accordion>
            </div>

            <hr/>

            <div className="versions__list">
                <h2>Copies</h2>
                {
                    copies?.length ?
                        <ul>
                            {
                                copies.filter(c => c.id !== undefined && c.id !== picData.id).map((copy, index) =>
                                    <li key={index}><a href={`/builder/${copy.id}`}>{copy.name}</a></li>
                                )
                            }
                        </ul>
                        : ''
                }
                <button className="btn-white" onClick={makeCopy} disabled={!picData.id}>Make a copy</button>
            </div>

            <hr/>

            <h2>Production</h2>
            {/*<div className="gantt">*/}
            {/*    <div className="production-pre" style={{width: `${getConvertedDuration(getPreparationDuration()) * 100 / getConvertedDuration(getTotalDuration())}%`}}/>*/}
            {/*    <div className="production-fabrication" style={{width: `${getConvertedDuration(getProductionDuration()) * 100 / getConvertedDuration(getTotalDuration())}%`}}/>*/}
            {/*    <div className="production-post" style={{width: `${getConvertedDuration(getPostDuration()) * 100 / getConvertedDuration(getTotalDuration())}%`}}/>*/}
            {/*</div>*/}

            <div className="separator">{getTotalDuration()}</div>

            <div className="production-estimation">
                <p>Estimated capacity <InfoBlack className="info-icone"/></p>
                <p className="estimation">? builds / week</p>
            </div>

            <hr/>

            <button className="button-production"
                    disabled={isDisabled()}
                    onClick={() => handleClickProduction()}>
                <ProductionNav className="production-icone"/> <h3>Next</h3>
            </button>
        </div>
    )
}
