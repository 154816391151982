import {OutlinedCard} from "../../common/OutlinedCard";
import {EnvironmentDonutChart} from "../charts/EnvironmentDonutChart";
import {FarmDialogGraph} from "../charts/FarmDialogGraph";
import React, {useEffect, useMemo, useState} from "react";
import {isUserGranted} from "../../../CommonFunctions";
import {createMuiTheme, Slider, ThemeProvider} from "@material-ui/core";
import {isMaintenanceRunning, TEMPERATURE_MAX} from "../../../../services/FarmService";

export const TemperatureBlock = (props) => {

    const {temperature, temperaturesList, isCommandEnabled, farmEvents,
        temperatureCommandData, unitPeriod, handleTemperatureChange, structureMaintenance, cellMaintenance} = props;

    const [temperatureSlider, setTemperatureSlider] = useState(temperatureCommandData);

    const isInMaintenance = isMaintenanceRunning(cellMaintenance) || isMaintenanceRunning(structureMaintenance);

    useEffect(() => {
        setTemperatureSlider(temperatureCommandData);
    }, [temperatureCommandData]);

    const temperatureColor = '#FF8800';

    const marksTemperature = [
        {value: 10, label: '10°C'},
        {value: 50, label: '50°C'},
    ];

    const muiSliderTheme = createMuiTheme({
        overrides:{
            MuiSlider: {
                thumb:{
                    color: 'black'
                },
                track: {
                    color: temperatureColor,
                },
                rail: {
                    color: 'black',
                }
            }
        }
    });

    const temperatureChart = useMemo(() => {
       return(
           <FarmDialogGraph
               data={temperaturesList} farmEvents={farmEvents} label="Temperature (°C)" color={temperatureColor}
               ymin={15} ymax={45} unitPeriod={unitPeriod} chartHeight={180}
           />
       );
    }, [temperaturesList, unitPeriod]);

    return(
        <>
            <div className="farm__dialog_environment_blocks">
                <h2>Temperature</h2>
                <div className="farm__dialog_environment_blocks-flex">
                    <div className="environment-left-block">
                        <div className="environment-left-block-line">
                            <EnvironmentDonutChart
                                colorData={temperatureColor} percentageData={temperature * 100 / TEMPERATURE_MAX}
                                label={(temperature ? Math.floor(temperature) : '--') + '°C'}
                            />
                        </div>
                        <div className="environment-left-block-line">
                            {(isUserGranted('ROLE_MANAGE_SMART_FARM') && isCommandEnabled &&
                                    (isInMaintenance || process.env.REACT_APP_HIDE_SF_SLIDERS_NO_MAINTENANCE === 'false')) &&
                                <div>
                                    <div className="environment-slider">
                                        <div className="environment-slider-content">
                                            <ThemeProvider theme={muiSliderTheme}>
                                                <Slider
                                                    marks={marksTemperature}
                                                    value={temperatureSlider}
                                                    valueLabelDisplay="on"
                                                    min={10}
                                                    max={50}
                                                    onChange={(e, value) => setTemperatureSlider(value)}
                                                    onChangeCommitted={(e, value) => handleTemperatureChange(value)}
                                                    aria-labelledby="continuous-slider"
                                                />
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                    <div className="environment-slider-legend"></div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="environment-graph-block">
                        <div className="environment-graph">
                            {temperatureChart}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
