import {OutlinedCard} from "../../common/OutlinedCard";
import React, {useEffect, useState} from "react";
import {FarmProfileModal} from "../../farm-profiles/FarmProfileModal";
import {Tooltip} from "@material-ui/core";
import {findRunningProfileId} from "../../../../services/FarmService";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";

export const EnvironmentProfilesBlock = (props) => {

    const {farmProfiles, systemType, setAlert, environmentData, setEnvironmentData, handleTemperatureChange,
        getFarmProfiles, runningProfileId, setRunningProfileId, farmCell} = props;

    const [isEnvironmentProfilesModalOpen, setIsEnvironmentProfilesModalOpen] = useState(false);
    const [filteredFarmProfiles, setFilteredFarmProfiles] = useState([]);

    useEffect(() => {
        if(farmProfiles) {
            setFilteredFarmProfiles(farmProfiles.filter(profile => profile.system_type === systemType));
            // setRunningProfileId(findRunningProfileId(farmProfiles, environmentData, systemType));
        }
    }, [farmProfiles]);

    /* Run selected profile */
    const activateProfile = (profile) => {
        // sendFarmCommandEvent(farmCell.system_code, 'temperature_profile', profile.temperature);
        sendFarmCommandEvent(farmCell.system_code, 'temperature', profile.temperature);
        // setEnvironmentData({
        //     'temperature': profile.temperature,
        //     'air_extraction': environmentData.air_extraction
        // });
        // if(profile.temperature !== undefined && profile.temperature !== null) {
        //     handleTemperatureChange(profile.temperature, profile.id);
        // }
        setRunningProfileId(profile.id);
        localStorage.setItem('profile_' + farmCell.system_code, profile.id);
    }

    /* Cancel running profile actions and deselect it */
    const cancelProfile = () => {
        // sendFarmCommandEvent(farmCell.system_code, 'temperature_profile', -1);
        sendFarmCommandEvent(farmCell.system_code, 'temperature', -1);
        // setEnvironmentData({
        //     'temperature': 0,
        //     'air_extraction': environmentData.air_extraction
        // });
        // handleTemperatureChange(0);
        setRunningProfileId(null);
        localStorage.removeItem('profile_' + farmCell.system_code);
    }

    const handleProfileClick = (profile) => {
        if(profile.id === runningProfileId) {
            cancelProfile();
        } else {
            activateProfile(profile);
        }
    }

    /* Get content of profile details in tooltip */
    const getProfileTooltipContent = (profile) => {
        return(
            <div className="farm-manager-profile-tooltip-text">
                {(profile.temperature !== undefined && profile.temperature !== null) &&
                    <div>{'Temperature: ' + profile.temperature + '°C'}</div>
                }
                {(profile.humidity !== undefined && profile.humidity !== null) &&
                    <div>{'Humidity: ' + profile.humidity + '%'}</div>
                }
            </div>
        );
    }

    return(
        <>
            {/* Profiles modal */}
            <FarmProfileModal
                isOpen={isEnvironmentProfilesModalOpen} closeModal={() => setIsEnvironmentProfilesModalOpen(false)}
                systemType={systemType} farmProfiles={filteredFarmProfiles} getFarmProfiles={getFarmProfiles}
            />

            <div className="farm__dialog_environment_profiles">
                {/* Header */}
                <div className="header-block">
                    <h2>Environment Profiles</h2>
                </div>
                <div>
                    <button className="button-outlined-main" type="button"
                            onClick={() => setIsEnvironmentProfilesModalOpen(true)}>
                        Manage profiles
                    </button>
                    {filteredFarmProfiles.map(profile =>
                        <Tooltip title={getProfileTooltipContent(profile)} arrow placement="top" key={profile.id}>
                            <button
                                className={profile.id === runningProfileId ? 'profile-button profile-button-running' : 'profile-button'}
                                type="button" onClick={() => handleProfileClick(profile)}
                            >
                                {profile.name}
                            </button>
                        </Tooltip>
                    )}
                    {(!filteredFarmProfiles || filteredFarmProfiles.length === 0) &&
                        <p>No profile</p>
                    }
                </div>
            </div>
        </>
    );
}
