import {CELL_TYPES, getFarmVersion, SMART_FARM_DATA_FIELDS} from "../../../services/FarmService";
import {FarmManagerBlock} from "./blocks/FarmManagerBlock";
import {OutlinedCard} from "../common/OutlinedCard";
import React from "react";

export const FarmDialogStructure = (props) => {

    const {getChartsDataRangeBlock, getTemperatureBlock, getHumidityBlock, getPollutionBlock, farmVersion,
        isGeneralBuzzerOn, generalCommandsInProcess, setGeneralCommandsInProcess, getMaintenanceBlock,
        structureMaintenance, structureId, isFarmDataGeneralMaintenance, farmStructure} = props;

    const isEnvironmentDataAvailable = farmVersion && getFarmVersion(farmVersion) === 2;

    return(
        <div className="farm-dialog-body">

            <FarmManagerBlock
                isGeneralBuzzerOn={isGeneralBuzzerOn} setGeneralCommandsInProcess={setGeneralCommandsInProcess}
                generalCommandsInProcess={generalCommandsInProcess} farmStructure={farmStructure}
            />

            <OutlinedCard>
                {isEnvironmentDataAvailable &&
                    <>
                        {getChartsDataRangeBlock()}

                        <hr/>

                        {getTemperatureBlock(false)}

                        <hr/>

                        {getHumidityBlock()}
                        {/*{getPollutionBlock(false)}*/}
                    </>
                }
            </OutlinedCard>

            {getMaintenanceBlock(structureMaintenance, null, structureId, null, isFarmDataGeneralMaintenance)}
        </div>
    );
}