import {FarmCell} from "./FarmCell";
import React from "react";
import {CELL_TYPES, DOOR_STATES, getFarmElementClassesWithMargins} from "../../../services/FarmService";

export const FarmModule = (props) => {

    const {farmModule, smartFarmData, handleClickOpenRightPart,isModuleFirstElement, isModuleLastElement, isLastModule,
        setIsWeighModalOpen, smartPrintersStatusData, manualPrintersStatus, farmMaintenances} = props;

    // Exclude balances from farm cells
    let farmCells =
        (farmModule && farmModule.farm_cells && farmModule.farm_cells.length > 0) ?
        farmModule.farm_cells.filter(cell => cell.system_type !== CELL_TYPES.BALANCE) :
        [];

    farmCells.sort((a, b) => {
        return a.position - b.position;
    });

    /* Get cell height after removing space for margins between cells */
    const getCellsHeight = (cells) => {
        let heights = [];
        if(cells && cells.length > 0) {
            let remainingHeight = 100;
            for(let i = 0; i < cells.length; i++) {
                if(i < cells.length - 1) {
                    const newHeight = cells[i].height_percentage;
                    remainingHeight -= newHeight;
                    heights.push(newHeight);
                } else {
                    heights.push(remainingHeight);
                }
            }
        }
        return heights;
    }

    const heights = getCellsHeight(farmCells);

    /* Get top module cell system_code (cell which handle influxdb module data) */
    const getTopCellSystemCode = (cells) => {
        if(cells) {
            // Try first to find a T-0X environment
            for(let cell of cells) {
                if(cell.system_code && cell.system_code.charAt(0) === 'T') {
                    return cell.system_code;
                }
            }
            // Otherwise, find an R-0X environment (case of materials on top)
            for(let cell of cells) {
                if(cell.system_code && cell.system_code.charAt(0) === 'R') {
                    return cell.system_code;
                }
            }
        }
        return null;
    }

    const topCellSystemCode = getTopCellSystemCode(farmCells);
    const topSmartFarmCellData = topCellSystemCode !== null ? smartFarmData?.[topCellSystemCode] : {};

    /* Get one footer foot */
    const getFoot = () => {
        return(
            <div className="farm-module-foot">
                <div className="module-footer-top-box">
                    <div className="module-footer-top"></div>
                </div>
                <div className="module-footer-bottom"></div>
            </div>
        );
    }

    /* Get door status text */
    const getDoorStatus = () => {
        if(topSmartFarmCellData) {
            if(topSmartFarmCellData.door_state === 1) {
                return 'Door closed';
            } else {
                return 'Door opened';
            }
        }
        return '--';
    }

    const isCellInMaintenance = (cellId) => {
        return (farmMaintenances && (
            // Check if general maintenance
            (farmMaintenances.structure && farmMaintenances.structure.start_date && !farmMaintenances.structure.end_date) ||
            // Check if maintenance on specific cell
            (farmMaintenances.cells && farmMaintenances.cells[cellId] && farmMaintenances.cells[cellId].start_date && !farmMaintenances.cells[cellId].end_date)
        ));
    }

    return(
        <div
            className={getFarmElementClassesWithMargins('farm-module', isModuleFirstElement, isModuleLastElement)}
            style={{marginRight: isLastModule ? 0 : '3px'}}
        >
            {/* header */}
            <div className="farm-module-header">
                <div className="module-element-block-center">
                    <div className={(topSmartFarmCellData && topSmartFarmCellData.door_state !== DOOR_STATES.CLOSED) ?
                        'module-header-door module-header-door-open' : 'module-header-door'}>
                        {getDoorStatus()}
                    </div>
                </div>
            </div>

            {/* body */}
            <div className="farm-module-body">
                {farmCells.map((cell, index) =>
                    <FarmCell
                        farmCell={cell} cellHeight={heights[index]} smartFarmCellData={smartFarmData?.[cell.system_code]}
                        key={cell.id} handleClickOpenRightPart={handleClickOpenRightPart}
                        setIsWeighModalOpen={setIsWeighModalOpen} smartPrintersStatusData={smartPrintersStatusData}
                        manualPrintersStatus={manualPrintersStatus} isCellInMaintenance={isCellInMaintenance(cell.id)}
                    />
                )}
            </div>

            {/* footer */}
            <div className="farm-module-footer">
                {getFoot()}
                {getFoot()}
            </div>
        </div>
    );
}
